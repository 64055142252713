<template>
  <div>
    <b-card class="mb-1 mt-2">
      <div class="card-title d-flex justify-content-between align-items-center">
        Settings
      </div>
      <div>
        <div v-for="setting in config" :key="setting.id" class="row">
          <div class="col-12"></div>
        </div>
        <b-tabs vertical nav-wrapper-class="nav-vertical">
          <b-overlay :show="isBusy" rounded="sm" :variant="skin">
            <b-tab v-for="setting in config" :key="setting.id">
              <template #title>
                <strong style="margin-right: auto">{{ setting.label }}</strong>
              </template>
              <b-card-text v-if="true" class="config">
                <b-card
                  v-for="children in setting.children"
                  :title="children.label"
                  :key="children.id"
                  sub-title=""
                >
                  <div class="row">
                    <b-form-group
                      v-for="item in children.children"
                      :key="item.id"
                      class="col-md-12"
                    >
                      <!-- #default="{ errors }" -->
                      <validation-provider
                        :name="item.key"
                        rules=""
                        #default="validationContext"
                      >
                        <b-form-group
                          v-if="item.type == 'number'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <b-form-input
                            :id="item.key"
                            :name="`custom_fields_data[${item.key}-${item.id}]`"
                            v-model="data_local[item.key]"
                            :value="
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            "
                            :placeholder="item.label"
                            type="number"
                            :state="getValidationState(validationContext)"
                          />
                        </b-form-group>

                        <b-form-group
                          v-if="item.type == 'text'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <b-form-input
                            :id="item.key"
                            :name="`custom_fields_data[${item.key}-${item.id}]`"
                            v-model="data_local[item.key]"
                            :value="
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            "
                            :placeholder="item.label"
                            type="text"
                            :state="getValidationState(validationContext)"
                          />
                        </b-form-group>
                        <b-form-group
                          v-if="item.type == 'radio'"
                          :label="item.label"
                          :label-for="item.key"
                        >
                          <div class="demo-inline-spacing">
                            <b-form-radio-group
                              :id="item.key"
                              v-model="data_local[item.key]"
                              :name="item.key"
                              :options="item.options"
                            />
                          </div>
                        </b-form-group>
                        <b-form-group
                          v-if="item.type == 'checkbox'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <div class="demo-inline-spacing">
                            <b-form-checkbox
                              v-model="data_local[item.key]"
                              switch
                              inline
                              :value="
                                data_local.get_customfields_data[
                                  'field_' + item.id
                                ]
                              "
                            >
                              Status
                            </b-form-checkbox>
                          </div>
                        </b-form-group>

                        <b-form-group
                          v-if="item.type == 'url'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <b-form-input
                            :id="item.key"
                            :name="`custom_fields_data[${item.key}-${item.id}]`"
                            v-model="data_local[item.key]"
                            :value="
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            "
                            :placeholder="item.label"
                            type="url"
                            :state="getValidationState(validationContext)"
                          />
                        </b-form-group>

                        <b-form-group
                          v-if="item.type == 'file'"
                          :label="item.label"
                          :label-for="item.key"
                        >
                          <b-form-file
                            :id="item.key"
                            v-model="data_local[item.key]"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                        </b-form-group>

                        <b-form-group
                          v-if="item.type == 'rating'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <div class="d-flex align-items-center">
                            <star-rating
                              v-model="
                                data_local.get_customfields_data[
                                  'field_' + item.id
                                ]
                              "
                              :show-rating="false"
                              :star-size="30"
                            ></star-rating>
                            <span
                              v-if="
                                data_local.get_customfields_data[
                                  'field_' + item.id
                                ]
                              "
                              :class="`rating-btn btn ${
                                ratingdescription[
                                  data_local.get_customfields_data[
                                    'field_' + item.id
                                  ]
                                ].class
                              }`"
                            >
                              {{
                                ratingdescription[
                                  data_local.get_customfields_data[
                                    "field_" + item.id
                                  ]
                                ].text
                              }}</span
                            >
                            <input
                              type="hidden"
                              v-model="
                                data_local.get_customfields_data[
                                  'field_' + item.id
                                ]
                              "
                              :name="`custom_fields_data[${item.name}-${item.id}]`"
                            />
                          </div>
                        </b-form-group>

                        <b-form-group
                          v-if="item.type == 'password'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="
                              getValidationState(validationContext) > 0
                                ? 'is-invalid'
                                : null
                            "
                          >
                            <b-form-input
                              :id="item.name"
                              :name="`custom_fields_data[${item.name}-${item.id}]`"
                              v-model="data_local[item.key]"
                              :value="item.value"
                              :placeholder="item.label"
                              :type="passwordFieldType"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>

                        <b-form-group
                          v-if="item.type == 'textarea'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <b-form-textarea
                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                            v-model="data_local[item.key]"
                            :value="
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            "
                            trim
                            :placeholder="item.label"
                          />
                        </b-form-group>
                        <b-form-group
                          v-if="item.type == 'date'"
                          :label="item.label"
                          label-for="input-1"
                        >
                          <flat-pickr
                            :config="{
                              dateFormat: 'Y-m-d',
                            }"
                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                            v-model="data_local[item.key]"
                            :value="
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            "
                            class="form-control"
                            placeholder="Select Date.."
                          />
                        </b-form-group>

                        <small class="text-danger">{{
                          validationContext.errors[0]
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-md-12">
                      <b-button
                        type="submit"
                        class="min-w-200"
                        variant="success"
                        @click="formSubmitted"
                      >
                        Save
                      </b-button>
                    </b-form-group>
                  </div>
                </b-card>
              </b-card-text>
            </b-tab>
            <b-tab>
              <template #title>
                <strong style="margin-right: auto">Community </strong>
              </template>
              <b-card-text v-if="true" class="config">
                <b-card title="Community" key="Community" sub-title="">
                  <div>
                    <!-- Table Container Card -->
                    <b-button
                      variant="success"
                      size="sm"
                      v-b-modal.modal-channel-add
                    >
                      <span class="text-nowrap">Add Channel</span>
                    </b-button>
                    <b-card no-body class="mb-0 mt-2">
                      <div class="">
                        <b-list-group>
                          <b-list-group-item>
                            <b-row>
                              <b-col cols="2"> # </b-col>
                              <b-col cols="6"> Channel Name</b-col>
                              <b-col cols="4"> Action </b-col>
                            </b-row>
                          </b-list-group-item>
                          <b-list-group-item
                            v-for="(channel, index) in communityChannels"
                            :key="channel.hashid"
                          >
                            <b-row>
                              <b-col cols="2">
                                {{ ++index }}
                              </b-col>
                              <b-col cols="6">
                                {{ channel.channel_name }}
                              </b-col>
                              <b-col cols="4">
                                <feather-icon
                                  v-if="
                                    channel.channel_name != 'Community' &&
                                    channel.channel_name != 'Feedback Box'
                                  "
                                  @click="editChannel(channel)"
                                  v-b-modal.modal-channel-edit
                                  v-b-tooltip.hover
                                  title="Edit"
                                  icon="EditIcon"
                                  size="16"
                                  class="mr-1 cursor-pointer"
                                />
                                <b-dropdown
                                  v-if="
                                    channel.channel_name != 'Community' &&
                                    channel.channel_name != 'Feedback Box'
                                  "
                                  variant="link"
                                  size="sm"
                                  toggle-class="text-decoration-none"
                                  no-caret
                                >
                                  <template v-slot:button-content>
                                    <feather-icon
                                      icon="MoreVerticalIcon"
                                      size="16"
                                      class="text-body align-middle mr-25"
                                    />
                                  </template>
                                  <b-dropdown-item
                                    class="btn-sm p-0"
                                    @click="confirmDeleteRecord(channel)"
                                  >
                                    <feather-icon
                                      icon="TrashIcon"
                                      size="14"
                                      class="mr-50"
                                    />
                                    <span>Delete</span>
                                  </b-dropdown-item>
                                </b-dropdown>
                              </b-col>
                            </b-row>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </b-card>
                  </div>
                </b-card>
              </b-card-text>
            </b-tab>
          </b-overlay>
        </b-tabs>
      </div>
    </b-card>

    <b-modal
      id="modal-channel-add"
      ref="modal"
      title="Add new Channel"
      @ok="addChannel"
      @show="resetModal"
    >
      <div>
        <b-form-group label-for="channelName" label="Channel Name">
          <b-form-input
            id="channelName"
            v-model="community_data.channel_name"
            placeholder="Channel Name"
            name="name"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-for="channelDescription"
          label="Channel Description"
        >
          <b-form-textarea
            id="channelDescription"
            v-model="community_data.description"
            placeholder="Channel Description"
            name="description"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          label-for="ChannelColor"
          label="Channel Color"
        >
        <colour-picker
        v-model="community_data.channel_color" :color="color" :colorOptions="colors" />
        
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-channel-edit"
      ref="modal-channel-edit"
      title="Channel Edit"
      @ok="updateChannel"
    >
      <div>
        <b-form-group label-for="channelName" label="Channel Name">
          <b-form-input
            id="channelName"
            v-model="community_data.channel_name"
            placeholder="Channel Name"
            name="name"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-for="channelDescription"
          label="Channel Description"
        >
          <b-form-textarea
            id="channelDescription"
            v-model="community_data.description"
            placeholder="Channel Description"
            name="description"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          label-for="ChannelColor"
          label="Channel Color"
        >
        <colour-picker
        v-model="community_data.channel_color" :color="color" :colorOptions="colors"/>
        
        </b-form-group>
        
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="ok()"> Update </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BCardText,
  BTabs,
  BTab,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import settingsStoreModule from "./settingsStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import ColourPicker from "@/components/CustomColorPicker.vue";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BTabs,
    BTab,
    FilePond,
    ColourPicker
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      skin: store.state.appConfig.layout.skin,
      config: "",
      all_notifications: false,
      allSelected: false,
      isBusy: false,
      redirectEmailNotification: "",
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      formData: {
        url: "settings",
      },
      subscriptions: [],
      communityChannels: [],
      searchQuery: "",
      community_data: {
        channel_name: "",
        description: "",
        channel_color:"",
      },
      color:'#218F17',
      colors:[
        '#137BB6',
        '#218F17',
        '#DB9901',
        '#1F767E',
        '#A81496',
        '#223F58',
        '#36464E',
        '#9C4E22',
      ],
      filterByType: "",
      typeOption: [
        {
          code: "one",
          label: "One",
        },
        {
          code: "many",
          label: "Many",
        },
      ],
    };
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-settings";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getModuleData();
    this.getCommunitychannels();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    searchQuery(val) {
      this.getUserEmailSubscriptions();
    },
    filterByType(val) {
      this.getUserEmailSubscriptions();
    },
  },
  methods: {
    formSubmitted() {
      const self = this;

      this.isBusy = true;

      self.formData.url = "settings/config";
      self.formData.params = self.data_local;

      this.$store
        .dispatch("app/store", self.formData)
        .then((res) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Config Updated",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Config Updation Failed",
              icon: "XCircleIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getModuleData() {
      const self = this;
      let params = [];
      params.url = "settings/create";
      self.$store
        .dispatch("app/get", params)
        .then((res) => {
          self.config = res.data.data;
          self.config.forEach(function (item) {
            item.children.forEach(function (children) {
              children.children.forEach(function (field) {
                if (field.type != "file") {
                  self.data_local[field.key] = field.value;
                }
                if (field.type == "radio") {
                  console.log(field);
                }
              });
            });
          });
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Config Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getCommunitychannels() {
      const self = this;
      let params = [];
      this.isBusy = true;
      params.url = "community-channels";
      self.$store
        .dispatch("app/get", params)
        .then((res) => {
          self.communityChannels = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! community Channels",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    addChannel() {
      const self = this;
      let data = [];
      data.url = "community-channels";
      data.params = {
        channel_name: this.community_data.channel_name,
        description: this.community_data.description,
        channel_color: this.community_data.channel_color,
      };
      self.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.community_data.channel_name = "";
          this.community_data.description = "";
          this.community_data.channel_color = "";
          this.getCommunitychannels();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Channel Added successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Adding Channels Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    updateChannel() {
      const self = this;
      let data = [];
      data.url = "community-channels/" + this.community_data.hashid;
      data.params = {
        channel_name: this.community_data.channel_name,
        description: this.community_data.description,
        channel_color: this.community_data.channel_color,
        _method: "PATCH",
      };
      self.$store
        .dispatch("app/update", data)
        .then((res) => {
          this.community_data.channel_name = "";
          this.community_data.description = "";
          this.community_data.channel_color = "";
          this.getCommunitychannels();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Channel Updated successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Adding Channels Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    removeChannel(channel) {
      const self = this;

      this.isBusy = true;
      self.formData.url = "community-channels/" + channel.hashid;

      this.$store
        .dispatch("app/delete", self.formData)
        .then((res) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Channel Removed",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.getCommunitychannels();
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Channel Removing Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error.text,
            },
          });
        });
    },
    editChannel(channel) {
      const self = this;
      self.community_data = {
        hashid: channel.hashid,
        channel_name: channel.channel_name,
        description: channel.description,
        channel_color: channel.channel_color,
      };
    },
    resetModal() {
      const self = this;
      self.community_data = {
        hashid: "",
        channel_name: "",
        description: "",
        channel_color: "",
      };
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.channel_name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeChannel(data);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
