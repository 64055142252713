var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-1 mt-2"},[_c('div',{staticClass:"card-title d-flex justify-content-between align-items-center"},[_vm._v(" Settings ")]),_c('div',[_vm._l((_vm.config),function(setting){return _c('div',{key:setting.id,staticClass:"row"},[_c('div',{staticClass:"col-12"})])}),_c('b-tabs',{attrs:{"vertical":"","nav-wrapper-class":"nav-vertical"}},[_c('b-overlay',{attrs:{"show":_vm.isBusy,"rounded":"sm","variant":_vm.skin}},[_vm._l((_vm.config),function(setting){return _c('b-tab',{key:setting.id,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('strong',{staticStyle:{"margin-right":"auto"}},[_vm._v(_vm._s(setting.label))])]},proxy:true}],null,true)},[(true)?_c('b-card-text',{staticClass:"config"},_vm._l((setting.children),function(children){return _c('b-card',{key:children.id,attrs:{"title":children.label,"sub-title":""}},[_c('div',{staticClass:"row"},[_vm._l((children.children),function(item){return _c('b-form-group',{key:item.id,staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":item.key,"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(item.type == 'number')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.key,"name":("custom_fields_data[" + (item.key) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data[
                              'field_' + item.id
                            ],"placeholder":item.label,"type":"number","state":_vm.getValidationState(validationContext)},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1):_vm._e(),(item.type == 'text')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.key,"name":("custom_fields_data[" + (item.key) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data[
                              'field_' + item.id
                            ],"placeholder":item.label,"type":"text","state":_vm.getValidationState(validationContext)},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1):_vm._e(),(item.type == 'radio')?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.key}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio-group',{attrs:{"id":item.key,"name":item.key,"options":item.options},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1)]):_vm._e(),(item.type == 'checkbox')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{attrs:{"switch":"","inline":"","value":_vm.data_local.get_customfields_data[
                                'field_' + item.id
                              ]},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}},[_vm._v(" Status ")])],1)]):_vm._e(),(item.type == 'url')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.key,"name":("custom_fields_data[" + (item.key) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data[
                              'field_' + item.id
                            ],"placeholder":item.label,"type":"url","state":_vm.getValidationState(validationContext)},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1):_vm._e(),(item.type == 'file')?_c('b-form-group',{attrs:{"label":item.label,"label-for":item.key}},[_c('b-form-file',{attrs:{"id":item.key,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1):_vm._e(),(item.type == 'rating')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('star-rating',{attrs:{"show-rating":false,"star-size":30},model:{value:(
                              _vm.data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            ),callback:function ($$v) {_vm.$set(_vm.data_local.get_customfields_data, 
                                'field_' + item.id
                              , $$v)},expression:"\n                              data_local.get_customfields_data[\n                                'field_' + item.id\n                              ]\n                            "}}),(
                              _vm.data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            )?_c('span',{class:("rating-btn btn " + (_vm.ratingdescription[
                                _vm.data_local.get_customfields_data[
                                  'field_' + item.id
                                ]
                              ].class))},[_vm._v(" "+_vm._s(_vm.ratingdescription[ _vm.data_local.get_customfields_data[ "field_" + item.id ] ].text))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            ),expression:"\n                              data_local.get_customfields_data[\n                                'field_' + item.id\n                              ]\n                            "}],attrs:{"type":"hidden","name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]")},domProps:{"value":(
                              _vm.data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data_local.get_customfields_data, 
                                'field_' + item.id
                              , $event.target.value)}}})],1)]):_vm._e(),(item.type == 'password')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-input-group',{staticClass:"input-group-merge",class:_vm.getValidationState(validationContext) > 0
                              ? 'is-invalid'
                              : null},[_c('b-form-input',{attrs:{"id":item.name,"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":item.value,"placeholder":item.label,"type":_vm.passwordFieldType,"state":_vm.getValidationState(validationContext)},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1):_vm._e(),(item.type == 'textarea')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-textarea',{attrs:{"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data[
                              'field_' + item.id
                            ],"trim":"","placeholder":item.label},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1):_vm._e(),(item.type == 'date')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                            dateFormat: 'Y-m-d',
                          },"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data[
                              'field_' + item.id
                            ],"placeholder":"Select Date.."},model:{value:(_vm.data_local[item.key]),callback:function ($$v) {_vm.$set(_vm.data_local, item.key, $$v)},expression:"data_local[item.key]"}})],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)}),_c('b-form-group',{staticClass:"col-md-12"},[_c('b-button',{staticClass:"min-w-200",attrs:{"type":"submit","variant":"success"},on:{"click":_vm.formSubmitted}},[_vm._v(" Save ")])],1)],2)])}),1):_vm._e()],1)}),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('strong',{staticStyle:{"margin-right":"auto"}},[_vm._v("Community ")])]},proxy:true}])},[(true)?_c('b-card-text',{staticClass:"config"},[_c('b-card',{key:"Community",attrs:{"title":"Community","sub-title":""}},[_c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-channel-add",modifiers:{"modal-channel-add":true}}],attrs:{"variant":"success","size":"sm"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add Channel")])]),_c('b-card',{staticClass:"mb-0 mt-2",attrs:{"no-body":""}},[_c('div',{},[_c('b-list-group',[_c('b-list-group-item',[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_vm._v(" # ")]),_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" Channel Name")]),_c('b-col',{attrs:{"cols":"4"}},[_vm._v(" Action ")])],1)],1),_vm._l((_vm.communityChannels),function(channel,index){return _c('b-list-group-item',{key:channel.hashid},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(++index)+" ")]),_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(channel.channel_name)+" ")]),_c('b-col',{attrs:{"cols":"4"}},[(
                                  channel.channel_name != 'Community' &&
                                  channel.channel_name != 'Feedback Box'
                                )?_c('feather-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-channel-edit",modifiers:{"modal-channel-edit":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"title":"Edit","icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.editChannel(channel)}}}):_vm._e(),(
                                  channel.channel_name != 'Community' &&
                                  channel.channel_name != 'Feedback Box'
                                )?_c('b-dropdown',{attrs:{"variant":"link","size":"sm","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{staticClass:"btn-sm p-0",on:{"click":function($event){return _vm.confirmDeleteRecord(channel)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon","size":"14"}}),_c('span',[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)],1)})],2)],1)])],1)])],1):_vm._e()],1)],2)],1)],2)]),_c('b-modal',{ref:"modal",attrs:{"id":"modal-channel-add","title":"Add new Channel"},on:{"ok":_vm.addChannel,"show":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                                var ok = ref.ok;
                                var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v(" Save ")])]}}])},[_c('div',[_c('b-form-group',{attrs:{"label-for":"channelName","label":"Channel Name"}},[_c('b-form-input',{attrs:{"id":"channelName","placeholder":"Channel Name","name":"name"},model:{value:(_vm.community_data.channel_name),callback:function ($$v) {_vm.$set(_vm.community_data, "channel_name", $$v)},expression:"community_data.channel_name"}})],1),_c('b-form-group',{attrs:{"label-for":"channelDescription","label":"Channel Description"}},[_c('b-form-textarea',{attrs:{"id":"channelDescription","placeholder":"Channel Description","name":"description"},model:{value:(_vm.community_data.description),callback:function ($$v) {_vm.$set(_vm.community_data, "description", $$v)},expression:"community_data.description"}})],1),_c('b-form-group',{attrs:{"label-for":"ChannelColor","label":"Channel Color"}},[_c('colour-picker',{attrs:{"color":_vm.color,"colorOptions":_vm.colors},model:{value:(_vm.community_data.channel_color),callback:function ($$v) {_vm.$set(_vm.community_data, "channel_color", $$v)},expression:"community_data.channel_color"}})],1)],1)]),_c('b-modal',{ref:"modal-channel-edit",attrs:{"id":"modal-channel-edit","title":"Channel Edit"},on:{"ok":_vm.updateChannel},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                                var ok = ref.ok;
                                var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v(" Update ")])]}}])},[_c('div',[_c('b-form-group',{attrs:{"label-for":"channelName","label":"Channel Name"}},[_c('b-form-input',{attrs:{"id":"channelName","placeholder":"Channel Name","name":"name"},model:{value:(_vm.community_data.channel_name),callback:function ($$v) {_vm.$set(_vm.community_data, "channel_name", $$v)},expression:"community_data.channel_name"}})],1),_c('b-form-group',{attrs:{"label-for":"channelDescription","label":"Channel Description"}},[_c('b-form-textarea',{attrs:{"id":"channelDescription","placeholder":"Channel Description","name":"description"},model:{value:(_vm.community_data.description),callback:function ($$v) {_vm.$set(_vm.community_data, "description", $$v)},expression:"community_data.description"}})],1),_c('b-form-group',{attrs:{"label-for":"ChannelColor","label":"Channel Color"}},[_c('colour-picker',{attrs:{"color":_vm.color,"colorOptions":_vm.colors},model:{value:(_vm.community_data.channel_color),callback:function ($$v) {_vm.$set(_vm.community_data, "channel_color", $$v)},expression:"community_data.channel_color"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }